import { FunctionComponent, memo } from 'react';

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const LogoText: FunctionComponent<Props> = ({
  color = 'white',
  width = 113,
  height = 26,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79969 25.49C7.42302 25.49 5.94135 25.3033 4.35469 24.93C2.79135 24.5567 1.56635 24.1483 0.679688 23.705L1.20469 18.945C4.28469 20.4383 6.99135 21.185 9.32469 21.185C10.5614 21.185 11.5297 20.9517 12.2297 20.485C12.9297 19.995 13.2797 19.2833 13.2797 18.35C13.2797 17.65 13.0814 17.0783 12.6847 16.635C12.3114 16.1683 11.763 15.7833 11.0397 15.48C10.3164 15.1533 9.16135 14.71 7.57469 14.15C6.08135 13.6367 4.84469 13.1 3.86469 12.54C2.88469 11.9567 2.10302 11.2217 1.51969 10.335C0.959688 9.42501 0.679688 8.31668 0.679688 7.01001C0.679688 4.91001 1.44969 3.21834 2.98969 1.93501C4.52969 0.651676 6.73469 0.0100098 9.60469 0.0100098C10.958 0.0100098 12.2997 0.161677 13.6297 0.46501C14.983 0.768344 16.2197 1.14168 17.3397 1.58501L16.8847 6.17001C15.438 5.54001 14.108 5.07334 12.8947 4.77001C11.7047 4.44334 10.5147 4.28001 9.32469 4.28001C7.99469 4.28001 6.97969 4.50168 6.27969 4.94501C5.57969 5.38834 5.22969 6.03001 5.22969 6.87001C5.22969 7.52334 5.41635 8.06001 5.78969 8.48001C6.16302 8.90001 6.66469 9.25001 7.29469 9.53001C7.94802 9.81001 8.90469 10.1483 10.1647 10.545C12.988 11.4317 15.0414 12.4583 16.3247 13.625C17.6314 14.7683 18.2847 16.3433 18.2847 18.35C18.2847 20.2633 17.6197 21.9317 16.2897 23.355C14.9597 24.7783 12.463 25.49 8.79969 25.49Z"
      fill={color}
    />
    <path
      d="M38.2024 19.19H29.2774L27.2474 25H22.9074L31.6224 0.50001H35.8574L44.5724 25H40.2324L38.2024 19.19ZM36.8024 15.27L33.7574 6.52001L30.6774 15.27H36.8024Z"
      fill={color}
    />
    <path
      d="M64.4174 11.595C67.5208 12.5983 69.0724 14.745 69.0724 18.035C69.0724 20.2283 68.3608 21.9433 66.9374 23.18C65.5141 24.3933 63.5658 25 61.0924 25H50.8724V0.50001H60.1124C64.7791 0.50001 67.1124 2.47168 67.1124 6.41501C67.1124 8.84168 66.2141 10.5683 64.4174 11.595ZM55.3524 10.125H59.4474C60.5441 10.125 61.3608 9.90334 61.8974 9.46001C62.4341 8.99334 62.7024 8.28168 62.7024 7.32501C62.7024 6.32168 62.4341 5.59834 61.8974 5.15501C61.3608 4.71168 60.5441 4.49001 59.4474 4.49001H55.3524V10.125ZM60.4274 21.01C61.7341 21.01 62.7258 20.7533 63.4024 20.24C64.1024 19.7033 64.4524 18.805 64.4524 17.545C64.4524 16.3083 64.0908 15.4333 63.3674 14.92C62.6674 14.3833 61.6874 14.115 60.4274 14.115H55.3524V21.01H60.4274Z"
      fill={color}
    />
    <path d="M76.2816 0.50001H80.7616V25H76.2816V0.50001Z" fill={color} />
    <path
      d="M100.265 25.49C97.9545 25.49 95.8779 24.965 94.0345 23.915C92.2145 22.8417 90.7795 21.3483 89.7295 19.435C88.7029 17.4983 88.1895 15.27 88.1895 12.75C88.1895 10.23 88.7029 8.01334 89.7295 6.10001C90.7795 4.16334 92.2145 2.67001 94.0345 1.62001C95.8779 0.546677 97.9545 0.0100098 100.265 0.0100098C102.575 0.0100098 104.64 0.546677 106.46 1.62001C108.303 2.67001 109.738 4.16334 110.765 6.10001C111.815 8.01334 112.34 10.23 112.34 12.75C112.34 15.27 111.815 17.4983 110.765 19.435C109.738 21.3483 108.303 22.8417 106.46 23.915C104.64 24.965 102.575 25.49 100.265 25.49ZM100.265 21.15C102.645 21.15 104.465 20.38 105.725 18.84C107.008 17.3 107.65 15.27 107.65 12.75C107.65 10.23 107.008 8.20001 105.725 6.66001C104.465 5.12001 102.645 4.35001 100.265 4.35001C97.8845 4.35001 96.0529 5.12001 94.7695 6.66001C93.5095 8.20001 92.8795 10.23 92.8795 12.75C92.8795 15.27 93.5095 17.3 94.7695 18.84C96.0529 20.38 97.8845 21.15 100.265 21.15Z"
      fill={color}
    />
  </svg>
);

export default memo(LogoText);
