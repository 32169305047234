export const discriminationTerms =
  'aboriginal,aborigines,afkomst,afrikaan,allochtoon,ambachtsslaaf,autochtoon,baboe,barbaar,basja,bastiaan,berber,blank,boschneger,boschnegerin,boschnegers,boslandcreool,bosneger,bosnegerin,bosnegers,casties,cimarron,creolen,creolenmoeder,creool,eskimo,etniciteit,etnische minderheden,exotisch,futoboi,gastarbeider,gekleurd,halfbloed,hottentot,immigrant,inboorling,indiaan,indiaans,indianen,indianin inheems,inlander,inlands,jap,jappen,jappenkampen,kaffer,kaffir,kafir,karboeger,karboneger,kasties,kaukasisch,khoi,kioromama,kleurling,koeli,koelie,koppensneller,mardijker,marron,marronage,mesties,migrant,mongolide,mongool,moor,moors,moren,morenhoofd,morenkop,mulat,mulatten,mulattin,neger,negerin,negros,negroïde,njai,oorsprong,opperhoofd,piraat,piraterij,poesties,primitief,primitieven,pygmee,quadroon,raciaal,racisme,ras,roots,scalp,skalp,slaaf,slavenhandel,stam,stamhoofd,swart,swarte,swartin,testies,voetoboy,wildeman,wilden,wit,zigeuner,zwart,zwarte,zwarte mensen,zwarten,zwartin';
export const colonialismTerms =
  'bewindhebber,bewindvoerder,bomba,bombay,cimarron,derde wereld,dwerg,expeditie,gouverneur,halfbloed,hottentot,inboorling,indiaan,indisch,indo,inheems,inlander,jap,jappen,jappenkampen,kaffer,kaffir,kafir,koelie,kolonie,lagelonenland,landhuis,marron,marronage,missie,missionaris,moor,moors,moren,mulat,octroon,ontdekken,ontdekking,ontdekkingsreis,ontwikkelingsland,oorspronkelijk,oosters,opperhoofd,oriëntaals,pinda,politionele actie,primitief,primitieven,pygmee,ras,rasch,slaaf,stam,stamhoofd,traditioneel,tropisch,westers,wilden,zendeling,zendelingen,zending';
export const slaveryTerms =
  'abolitionist,admiraliteit,angisa,baboe,baljaren,bediende,brandmerk,compagnie,contractarbeid,contractarbeider,dienaar,dienstbode,dienstmeid,dienstmeisje,herendiensten,huisslaaf,kamerling,knecht,koopvaardij,kostgrond,kostgrondje,kotomisie,kotomissie,lijfeigene,manumissie,marron,negerslaaf,opstand,page,plantaadje,plantage,plantage-eigenaar plantagehouder,plantagie,planter,poenale sanctie,schuldslavernij,slaaf,slaven,slavenhaler,slavenhandel,slavenmarkt,slavenschip,slavernij,slavin,spaanse bok,suikermolen,veiling,veldslaaf,vrijbrief,vrijgekocht,vrijverklaard,wegloper,wegloperskas,winti,zweep,zwepen';
export const slaveryLocationTerms =
  'africa,afrika,allada,aneho,angola,annobon,antongil baai,appa,arguin,aruba,atjeh,axim,badagri,bali,banda,banda eilanden,bangladesh,bantam,bantan,banten,barbados,batavia,bengalen,benin city,berbice,bonaire,borneo,brasil,brazilië,casteel del mina,ceylon,colombo,cormant,cormantijn,cormantin,coromandel,curaçao,dejima,delagoa,demerara,demerary,d’elmina,elmina,epe,epké,essequebo,essequibo,formosa,fort amsterdam,fort cormantijn,fort cormantin,fort gorée,fort kormantijn,fort kormantin,fort nassau,fort nieuw amsterdam,fort oranje,fort zeelandia,fort zelandia,ghana,goeree,gorée,goudkust,grand-popo,greijn,grein,grijn,guiana,guinea,guinee,guyana,hôi an,india,indische,indië,indonesië,jaquim,java,kaap,kaapkolonie,kongo,kormant,kormantijn,kormantin,loanga,madagaskar,makassa,malabar,malakka,malediven,meidorp,meiborg,mina,molukken,morenland,nederlands ceylon,nederlands guiana,nederlands nieuw guinea,nederlands-brazilië,nederlands-indië,nederlandse antillen,nieuw amsterdam,nieuw-nederland,offra,ouidah,papoea,papua,paramaribo,penghu eilanden,pernambuco,pescadorus,principe,saba,sao tomé,save,sint eustatius,sint jago,sint maarten,sint thomas,slavenkust,st. eustatius,st. jago,st. maarten,st. thomas,stabroek,sumatra,surat,suriname,tandkust,tobago,togo,tonkin,west-indische,west-indië,willemstad,zuid-afrika';
export const slaveryProductTerms =
  'amboinahout,bakkeljauw,bidkleed,bont,borstdoek,brandewijn,cauri,coffy,coromandelhout,djatihout,gemberpot,geweer,globe,goud,grein,haarlems bontje,haarlems streepje,hemelbol,hoofdtooi,ikatdoek,ikatten,indigo,ivoor,jenever,kapok,katoen,kauri,keramiek,koffie,koffy,koper,koto,lendedoek,lijnwaad,nautilusschelp,nootmuskaat,oostersche tapijt,oosterse tapijt,osnabrucks linnen,osnabrugs linnen,pangi,panji,pantje,parelmoer,peper,porcelein,porselein,raffles-stoel,scheepsmodel,schelp,snaphaan,suiker,suyker,tabak,tabakspot,textiel,tulband,veren,verfhout,verteldoek,vries,wereldbol,zeeglobe,zout,zoutvlees';
export const religionTerms = 'islamiet,mohammedaan,heiden';
export const genderSexualityTerms =
  'gay,hermafrodiet,homo,queer,trans,transgender,transsexueel';

export const defaultTerms = colonialismTerms;
